import Header from '../Header.vue'
import Footer from '../Footer.vue'
import CheckInService from '../../api/CheckInService.js'

export default{
	name:'IndexCatalogIndex',
	data(){
		return{
			CatalogGrottoList:[],//石窟
			CatalogLiteratureList:[],//文献
			CatalogRelicList:[],//文物
			CatalogBibliographyList:[],//论著
			CatalogSitesList:[],//遗址
			CatalogFigureList:[],//人物
			CatalogAdditionalList:[],//其他
			SearchWordsList:[],
			keyWord:'',//搜索内容
			catalogList:[
				{value:this.$CatalogBibliographyId,label:'论著'},
				{value:this.$CatalogLiteratureId,label:'论文'},
				{value:this.$CatalogRelicId,label:'文物'},
				{value:this.$CatalogSitesId,label:'遗址'},
				{value:this.$CatalogFigureId,label:'人物'},
				{value:this.$CatalogGrottoId,label:'石窟'},
				{value:this.$CatalogAdditionalId,label:'其他'},
				],
			searchType:'',
			bannerList:[],
			defaultImg: 'this.src="' + require('../../images/索引目录数据库banner.jpg') + '"', //默认banner
		}
	},
	methods:{
		GetBanner(){ //获取banner
			var that = this
			CheckInService.GetBanner(1).then(res=>{
				if(res.Tag == 1){
					that.bannerList = res.Result
				}
			})
		},
		GetCatalogGrottoList(){ //获取石窟
			var that = this
			CheckInService.GetIndexClassResList(that.$CatalogGrottoId,4,0).then(res=>{
				if(res.Tag == 1){
					that.CatalogGrottoList = res.Result
				}
			})
		},
		GetCatalogLiteratureList(){ //获取文献
			var that = this
			CheckInService.GetIndexClassResList(that.$CatalogLiteratureId,6,0).then(res=>{
				if(res.Tag == 1){
					that.CatalogLiteratureList = res.Result
				}
			})
		},
		GetCatalogRelicList(){ //获取文物
			var that = this
			CheckInService.GetIndexClassResList(that.$CatalogRelicId,4,0).then(res=>{
				if(res.Tag == 1){
					that.CatalogRelicList = res.Result
				}
			})
		},
		GetCatalogBibliographyList(){ //获取论著
			var that = this
			CheckInService.GetIndexClassResList(that.$CatalogBibliographyId,3,0).then(res=>{
				if(res.Tag == 1){
					that.CatalogBibliographyList = res.Result
				}
			})
		},
		GetCatalogSitesList(){ //获取遗址
			var that = this
			CheckInService.GetIndexClassResList(that.$CatalogSitesId,9,0).then(res=>{
				if(res.Tag == 1){
					that.CatalogSitesList = res.Result
				}
			})
		},
		GetCatalogFigureList(){ //获取人物
			var that = this
			CheckInService.GetIndexClassResList(that.$CatalogFigureId,8,0).then(res=>{
				if(res.Tag == 1){
					that.CatalogFigureList = res.Result
				}
			})
		},
		GetCatalogAdditionalList(){ //获取其他
			var that = this
			CheckInService.GetIndexClassResList(that.$CatalogAdditionalId,9,0).then(res=>{
				if(res.Tag == 1){
					that.CatalogAdditionalList = res.Result
				}
			})
		},
		GetSearchWords(){ //获取搜索词
			var that = this
			CheckInService.GetSearchWords(5).then(res=>{
				if(res.Tag == 1){
					that.SearchWordsList = res.Result
				}
			})
		},
		toMoreList(typeName,classId,type){ //更多
			window.open(this.$WebUrl+'ElementList?classId='+classId + '&type='+ type + '&typeName='+ typeName)
		},
		toElementDetail(id){ //词条详情
			window.open(this.$WebUrl+'ElementDetail?id='+id)
		},
		toSearchList(){ //搜索
			var typeName=''
			this.catalogList.find(item =>{
				if(item.value == this.searchType){
					typeName = item.label
				}
			})
			window.open(this.$WebUrl+'ElementList?classId='+this.searchType + '&type='+ 0 + '&typeName='+ typeName + '&keyWord='+this.keyWord)
		},
		chooseSearch(word){
			this.keyWord = word
			this.toSearchList()
			console.log(this.keyWord)
		},
		toSeniorSearch(){ //高级检索
			this.$router.push({
				path:'/SeniorSearch'
			})
		}
	},
	created(){
		this.GetBanner()
		this.GetCatalogGrottoList()
		this.GetCatalogLiteratureList()
		this.GetCatalogRelicList()
		this.GetCatalogBibliographyList()
		this.GetCatalogSitesList()
		this.GetCatalogFigureList()
		this.GetCatalogAdditionalList()
		this.GetSearchWords()
		this.searchType = this.catalogList[0].value
	},
	components:{
		'Header': Header,
		'Footer':Footer
	}
}